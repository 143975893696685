import React, { Suspense, useEffect, useState } from "react";
import Layout from "./components/layout/Layout";
import { Route, Switch, useLocation } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
// import LoadingSpinner from "./components/ui/LoadingSpinner";
import { getSiteConfig } from "./store/authMethods";
import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";
// import { Helmet } from "react-helmet";

library.add(fas, far, fab);

// const Home = React.lazy(() => import("./pages/Home"));
// const StudentHome = React.lazy(() => import("./pages/student/Home"));
// const StaffHome = React.lazy(() => import("./pages/staff/Home"));

/* localStorage.removeItem("token");
localStorage.removeItem("user-name");
localStorage.removeItem("user-info");
localStorage.setItem("csrf-token", ''); */

/* localStorage.setItem("token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYTJhNTdjOGM5YTk1NTg2MWEwYWJjNzc0MGU2YmU5MGQ2OGM0ZDk1NTkyZmJlNzRlOTBlZjRiN2RhYmZhMzIwZDQ3YjExZTA0ZDE0OGY3ZDYiLCJpYXQiOjE3MzY5NDE3OTIuNDM4NDg2LCJuYmYiOjE3MzY5NDE3OTIuNDM4NDg4LCJleHAiOjE3MzcwMjgxOTIuNDMyODc5LCJzdWIiOiIxMCIsInNjb3BlcyI6W119.HJb3MygyvcjHqAgGx_FAscOFwQ3T-qfhGmmqinlyeYXgz8yGF4J1dLMfFucLDL3jkZ2zn6D8rTs_rhOUNqeI6_p3KhfaIougOC5dJFTj2HvMYCw-WzlkXf614aW6HNwP7ELtFTqWl-cYuEmFaCe5SM0j9LiX4OZLNJ-vxxtMf1hzJsJjRfNZG4X6VSi7UtPLmZoZYBGVfLubzKEKEQXUTedPcs0mz8jC2XLA8YMwbqAY5cGpbsU_NVLHv4_vo-mKEBksiId5Icls-_ZnBi2TgIi4qMEpXcxmSvVcAJBHnLsP9PiW-QCgFnqZTJHsxZxPCif6yJN6HvKdu5zo6hsXn7rBuUno_61sG1-xepxZSwuXX9nB5bQw15rfU8GA4BDTlBaTRtC40K2Z0_3IT_ZdXf78Z5xxwsCOgmn5r8LRW_Z9NFGaq8vA8cmZRSLNXSQ5FB6lX_XYaJU-KQ7WZr85Jx1a455RqeDuwz-XWTVS8khFFDcAp_qeFR6kOkAvp4RDdYYzS6OIsikD_ywG8-i8Eq47FKr3zUAXwdzdexg7lLjSYfZpitjq_UehuSnJ76KOcxjhxfao29-eOrqsK0XwZYlwqUzcsrqVxsyK3k0x1KN1jwluDVxrmlXpMj9lAfoGp_qq20DvLIedP6ByZL770IMpEDJS06r-eeLMJ1IYpdw");
localStorage.setItem("user-name", "UG Student");
localStorage.setItem("user-info", '{"id":10,"name":"UG Student","lastname":"Test","email":"testuser@uowd.com","email_verified_at":null,"accessToken":"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMjdkNDhjZmQ2ZjNjZjQ5MWE0YzU1M2EyMzRmYzA4YTY1ZGZiMDI5YzdmZmViYmNkNmExZTk0NWJlOWYwMmZkMGI4NDIyOGIyN2I3NWI3NWUiLCJpYXQiOjE3MzY4NTQyMzIuNDcxOTE2LCJuYmYiOjE3MzY4NTQyMzIuNDcxOTE4LCJleHAiOjE3MzY5NDA2MzIuNDY3MDcxLCJzdWIiOiIxMCIsInNjb3BlcyI6W119.gaoryM3VvUfS0c0eN_t3W6a1XMLIPJ8AQqCWGoxV8-3aCfDP4DjBbAmVFHXjh5tbWKwoePlV92WWcEEjTjA6HU12cn2xgJJ1gxb4lMR4BUubXBEgUFUm38zy3_uM55ybJK2v41BVjDDMnavYh8RocAWgWv5Fj-8ZivViBfLqPyxHPVFQvdii863ZLHXyfbhz9Q7yifheXTdBwvcESxZguwFhv0dhx6lYjisEGyraMkqbuqYtlH3a9gdTrviqAXCInHd6_8_nRcOqU1kphwaDVuydkopOEDS256baHVlfZxe0ASqKMOghVbqGOr5gJMQJpWaS7Z7rEu91-ydNX0ASIswXvjeGLJi5DOBMDDBBD8wgP4H-DymalzKXABEwLM2BBKa8qLlcUe-lALpxaTBxvWuFg4MFpvQlSmAgJwqFWxmde-SRuyrwcbVTf1Q_umQWCmFgO-Oje7UfR_822M1vg2PTMIbWm84TFHlLCqeZyQAu1iXe4JcuQFYsg16JI0eaml_ck0SJ48P8-Np90PjatiMbvYoUqxftIco1YiKuXovUsJoNsjy3BEhF69gCZFzOr6m4myT4tFhJxVhbaZIoYyEAQDGxn9AVo1Pgi65Q7d3XYFOEdyLSbsp_sTss2th4d3i6GjW909-2NbVMFuc9D39AyvL127FU5MBXfZOFtDs","created_at":"2022-04-27T06:30:53.000000Z","updated_at":"2025-01-14T11:30:32.000000Z","roleid":null,"type":"student","phone":null,"avatar":"","updated_by":0,"status":"Y","lastlogin":"2025-01-14 11:30:32","role":null}');
localStorage.removeItem("csrf-token"); */

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isGAReady, setIsGAReady] = useState(false);
  const [keywords, setKeywords] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    dispatch(getSiteConfig()).then((data) => {
      ReactGA.initialize(data.google_analytic_tracking_id);
      // ReactGA.initialize('G-4Z8CJV6T99');
      setKeywords(data.site_keywords);
      setDescription(data.site_description);
      setIsGAReady(true);
    });
  }, []);

  useEffect(() => {
    if (isGAReady) {
      // ReactGA.pageview(location.pathname + location.search);
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }
  }, [location.pathname, location.search, isGAReady]);

  return (
    <Layout>
      {/* {isGAReady && (
        <Helmet>
          <meta name="keywords" content={keywords} />
          <meta name="description" content={description} />
        </Helmet>
      )} */}
      {/* <Suspense fallback={<LoadingSpinner />}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/forgot-password" component={Home} />
        <Route path="/student" exact component={StudentHome} />
        <Route path="/staff" exact component={StaffHome} />
      </Switch>
      </Suspense> */}
    </Layout>
  );
}

export default App;
